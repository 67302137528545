import { Component } from "react";
import * as React from "react";
import ItemTable from "./ItemTable";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

// function getRandomInt(min, max) {
//   min = Math.ceil(min);
//   max = Math.floor(max);
//   return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
// }

function getId(id) {
  return id ? id : 1382012;
}

const styles = {
  media: {
    "image-rendering": "pixelated",
  },
};

export default class Maple extends Component {
  state = {
    data: 0,
    icon: 0,
    id: getId(this.props.match.params.id),
  };

  render() {
    return (
      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
      >
        <Grid item>
          <Card sx={{ maxWidth: 345 }}>
            <CardActionArea>
              <CardMedia
                component="img"
                height="auto"
                image={`https://api.maplestory.net/item/${this.state.id}/iconRaw`}
                alt="Maple Item"
                style={styles.media}
              />
              <CardContent>
                <Typography variant="body2" color="text.secondary">
                  <ItemTable id={this.state.id}></ItemTable>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    );
  }
}
