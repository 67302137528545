import React, { Component } from "react";
import Typography from "@mui/material/Typography";
const axios = require("axios");

export default class ItemTable extends Component {
  state = {
    item: null,
  };

  componentDidMount() {
    axios
      .get(`https://api.maplestory.net/item/${this.props.id}`)
      .then((res) => {
        const data = res.data;
        const item = {
          name: data.name,
          level: data.requiredStats.level,
          job: data.requiredStats.jobTrees.toString(),
        };
        this.setState({ item });
      });
  }

  render() {
    return (
      <div>
        <Typography variant="subtitle1">
          <u>Name</u>:&nbsp;{this.state.item?.name}
        </Typography>
        <Typography variant="subtitle1">
          <u>Level</u>:&nbsp;
          {this.state.item?.level}
        </Typography>
        <Typography variant="subtitle1">
          <u>Job</u>:&nbsp;
          {this.state.item?.job}
        </Typography>
      </div>
    );
  }
}
