import Home from "./Home/Home";
import Maple from "./Maple/Maple";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Router>
        <Route exact path={"/"} component={Home} />
        <Route exact path={"/maple/:id"} component={Maple} />
        <Route exact path={"/maple"} component={Maple} />
      </Router>
    </ThemeProvider>
  );
}

export default App;
