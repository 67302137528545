import React, { Component } from "react";
import Warp from "../Helpers/Wrap";
import "./Home.css";
const barny_picture = "../barny.png";

export default class Home extends Component {
  state = {
    visible: true,
  };

  Change(currectVisible) {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <div className="Home">
        <header className="Home-header">
          <Warp height="100"></Warp>
          <div className={this.state.visible ? "fadeIn" : "fadeOut"}>
            <img
              src={barny_picture}
              className="photo"
              onClick={() => this.Change(this.state.visible)}
              alt="barny_picture"
            ></img>
            <h4 style={{ font: "Ubuntu" }}>
              Something great is about to happen
            </h4>
          </div>
        </header>
      </div>
    );
  }
}
